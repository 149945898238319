// firebaseConfig.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
   apiKey: "AIzaSyBvEugwqJdGWgDW-GxAWLb9flt5_elI3OE",
  authDomain: "tech-wiz-e87f4.firebaseapp.com",
  projectId: "tech-wiz-e87f4",
  storageBucket: "tech-wiz-e87f4.appspot.com",
  messagingSenderId: "605807125155",
  appId: "1:605807125155:web:f37b7ddced3f54b41c5516",
  measurementId: "G-Y791NJG4KY"
};

firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
export default firebase;
const app = initializeApp(firebaseConfig);


export const db = firebase.firestore();