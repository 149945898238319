import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import Topbar1 from '../../componet/topbar/Topbar';
import './home.scss';

// Initialize Firebase app
const firebaseConfig = {
  apiKey: "AIzaSyBvEugwqJdGWgDW-GxAWLb9flt5_elI3OE",
  authDomain: "tech-wiz-e87f4.firebaseapp.com",
  projectId: "tech-wiz-e87f4",
  storageBucket: "tech-wiz-e87f4.appspot.com",
  messagingSenderId: "605807125155",
  appId: "1:605807125155:web:f37b7ddced3f54b41c5516",
  measurementId: "G-Y791NJG4KY"
};

firebase.initializeApp(firebaseConfig);

const Home = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    password: '', // Add password field
    category: '',
    experience: '',
    profileImage: null
  });
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationType, setNotificationType] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData(prevData => ({
      ...prevData,
      profileImage: file
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const { name, email, phoneNumber, password, category, experience, profileImage } = formData;
  
      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        throw new Error('Invalid email format. Please enter a valid email address.');
      }
  
      // Create user with validated email and password
      const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);
  
      const storageRef = firebase.storage().ref().child(`profileImages/${user.uid}`);
      const userToken = await user.getIdToken();
      await storageRef.put(profileImage, { headers: { Authorization: `Bearer ${userToken}` } });
      const imageUrl = await storageRef.getDownloadURL();
  
      await firebase.firestore().collection('admin-user').doc(user.uid).set({
        name,
        email,
        phoneNumber,
        category,
        experience,
        profileImage: imageUrl
      });
  
      setNotificationType('success');
      setNotificationMessage('Account created successfully!');
      setShowNotification(true);
  
      console.log('User created successfully:', user);
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setNotificationType('error');
        setNotificationMessage('The email address is already in use by another account. Please use a different email address.');
        setShowNotification(true);
      } else {
        setNotificationType('error');
        setNotificationMessage('Error creating account. Please try again.');
        setShowNotification(true);
      }
      console.error('Error creating user:', error);
    }
  };
  
  const closeNotification = () => {
    setShowNotification(false);
  };

  return (
    <div className="home">
      <Topbar1 />
      <div className="homeContainer">
        <h2>Create technology professional</h2>
        {showNotification && (
          <div className={`notification ${notificationType}`} onClick={closeNotification}>
            {notificationMessage}
          </div>
        )}
        <form className="accountForm" onSubmit={handleSubmit}>
          <div className="formGroup">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="formGroup">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="formGroup">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input type="text" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
          </div>
          <div className="formGroup">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} required />
          </div>
          <div className="formGroup">
            <label htmlFor="category">Category</label>
            <select id="category" name="category" value={formData.category} onChange={handleChange} required>
              <option value="">Select Category</option>
              <option value="Tech support">Tech support</option>
              <option value="coding">coding</option>
              <option value="grphics">grphics</option>
              {/* Add more options as needed */}
            </select>
          </div>
          <div className="formGroup">
            <label htmlFor="experience">Years of Experience</label>
            <input type="number" id="experience" name="experience" value={formData.experience} onChange={handleChange} required />
          </div>
          <div className="formGroup">
            <label htmlFor="profileImage">Profile Image</label>
            <input type="file" id="profileImage" name="profileImage" accept="image/*" onChange={handleImageChange} required />
          </div>
          <button className='but' type="submit">Create Account</button>
        </form>
      </div>
    </div>
  );
};

export default Home;
