import React from 'react'
import Login from '../src/pages/login/Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import LateVid from '../src/pages/latest videos/LateVid';
import UserA from '../src/pages/user account/UserA'
import Account from '../src/pages/account/Account'
import Language from '../src/pages/language/Language'
import NotificationsPage from '../src/pages/notification/NotificationsPage'
import Search from '../src/pages/search/Search'
import Setting from '../src/pages/setting/Settings'
import UserVideos from './pages/uservideos/UserVideos'
import Letblog  from './pages/latest blog/Letblog';
import VideoUp from './pages/upload/Videoup'
import Dashboard from './pages/dashboard/Dashboard'
import './app.scss'
const App = () => {
  return (
    <div className="app">
       <BrowserRouter>
      <Routes>
      <Route path="/" element={<Login />} />  
      <Route path="/Home" element={<Home />} />  
      <Route path="/LateVid" element={<LateVid />} />  
      <Route path="/UserA" element={< UserA/>} />  
      <Route path="/Account" element={<Account />} />  
        <Route path="/Language" element={<Language />} /> 
        <Route path="/Notifications" element={<NotificationsPage />} />  
        <Route path="/Search" element={<Search />} />
        <Route path="/Settings" element={<Setting />} />
        <Route path="/VideoUp" element={<VideoUp />} />
        <Route path="/Letblog" element={<Letblog />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/user-videos/:userId" element={<UserVideos />} />
        {/* Add other routes here */}
      </Routes>
    </BrowserRouter>
     
    </div>
  )
}

export default App